@tailwind base;
@tailwind components;
@tailwind utilities;

mark {
    background-color: #45454a !important;  /* Use your custom dark gray */
    color: #ffffff !important;             /* White text */
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
  }
  
  .trace-highlight {
    background-color: #1d1d22; /* For trace highlights */
    color: #ffffff;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
  }